import React, { useEffect, useState, useRef } from 'react';

// Warning sounds array
const warningSounds = [
    '/warning-sound-01.mp3',
    '/warning-sound-02.mp3',
    '/warning-sound-03.mp3'
];

const NoiseDetector = ({ isQuietTime, resetWarningSound }) => {
    const [noiseLevel, setNoiseLevel] = useState(0);
    const [threshold, setThreshold] = useState(100); // Default threshold
    const [cooldown, setCooldown] = useState(false);
    const [cooldownProgress, setCooldownProgress] = useState(0); // Progress bar state
    const [warningSoundIndex, setWarningSoundIndex] = useState(0); // Track which warning sound to play
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const microphoneRef = useRef(null);

    const maxNoiseLevel = 100;

    // Reset warning sound index when quiet time ends
    useEffect(() => {
        if (resetWarningSound) {
            setWarningSoundIndex(0); // Reset to the first sound
        }
    }, [resetWarningSound]);

    useEffect(() => {
        // Initialize microphone and audio context only once
        const initializeAudio = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const analyser = audioContext.createAnalyser();
                const microphone = audioContext.createMediaStreamSource(stream);
                microphone.connect(analyser);

                audioContextRef.current = audioContext;
                analyserRef.current = analyser;
                microphoneRef.current = microphone;
            } catch (err) {
                console.error('Microphone access error:', err);
            }
        };

        initializeAudio();

        const checkNoiseLevel = () => {
            if (cooldown || !analyserRef.current) return; // Skip measuring during cooldown or if analyser not ready

            const analyser = analyserRef.current;
            const dataArray = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(dataArray);
            const volume = dataArray.reduce((a, b) => a + b) / dataArray.length;

            // Normalize the volume to match the maxNoiseLevel and round to 2 decimals
            const normalizedVolume = Math.min((volume / 255 * maxNoiseLevel).toFixed(2), maxNoiseLevel);
            setNoiseLevel(Number(normalizedVolume)); // Convert back to number

            if (isQuietTime && normalizedVolume > threshold && !cooldown) {
                // Play the current warning sound
                const currentSound = new Audio(warningSounds[warningSoundIndex]);
                currentSound.play();

                // Update the warning sound index to the next sound
                setWarningSoundIndex((prevIndex) => (prevIndex + 1) % warningSounds.length);

                setCooldown(true);

                const cooldownTime = currentSound.duration * 1000 || 6000;

                // Start visualizing cooldown
                let progress = 0;
                const cooldownInterval = setInterval(() => {
                    progress += 100 / (cooldownTime / 100); // Increment the progress
                    setCooldownProgress(progress);
                    if (progress >= 100) {
                        clearInterval(cooldownInterval);
                        setCooldown(false); // End cooldown after it's done
                        setCooldownProgress(0); // Reset progress
                    }
                }, 100);
            }
        };

        const interval = setInterval(checkNoiseLevel, 100); // Check every 100ms
        return () => clearInterval(interval);
    }, [isQuietTime, threshold, cooldown, warningSoundIndex]); // Include warningSoundIndex in dependencies

    return (
        <div className="control-bar">
            <div className="control-item">
                <p>Noise Level:</p>
                <meter
                    value={noiseLevel}
                    min="0"
                    max={threshold}  // Set the max to the threshold 
                    low={threshold * 0.5}   // Green range
                    high={threshold * 0.9}  // Yellow range
                    optimum="0"             // Best value is no noise
                ></meter>
                <p>{noiseLevel} / {threshold}</p>
            </div>

            <div className="control-item">
                <label>Noise Threshold:</label>
                <input
                    type="range"
                    min="0"
                    max={maxNoiseLevel}
                    value={threshold}
                    onChange={(e) => setThreshold(e.target.value)}
                />
            </div>

            {/* Cooldown Progress Bar */}
            {cooldown && (
                <div className="progress-bar-container">
                    <div
                        className="progress-bar"
                        style={{ width: `${cooldownProgress}%` }}
                    ></div>
                </div>
            )}
        </div>
    );
};

export default NoiseDetector;
