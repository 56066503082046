import React from 'react';
import './TrafficLight.css';

const TrafficLight = ({ isQuietTime, toggleQuietTime }) => {
    return (
        <div 
            className={`traffic-light ${isQuietTime ? 'red' : 'green'}`}
            onClick={toggleQuietTime} // Add the onClick handler to toggle quiet time
        >
            {isQuietTime ? 'Quiet Time' : 'You can talk'}
        </div>
    );
};

export default TrafficLight;
