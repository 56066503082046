import React, { useState, useEffect } from 'react';
import TrafficLight from './TrafficLight';
import QuietTimeScheduler from './QuietTimeScheduler';
import NoiseDetector from './NoiseDetector';

const App = () => {
    const [isQuietTime, setIsQuietTime] = useState(false);
    const [quietTime, setQuietTime] = useState({ start: '', end: '' });
    const [manualQuietTime, setManualQuietTime] = useState(false);
    const [resetWarningSound, setResetWarningSound] = useState(false);

    useEffect(() => {
        const checkQuietTime = () => {
            const now = new Date();
            const currentTime = now.getHours() * 60 + now.getMinutes();
            const quietStart = parseInt(quietTime.start.split(':')[0]) * 60 + parseInt(quietTime.start.split(':')[1]);
            const quietEnd = parseInt(quietTime.end.split(':')[0]) * 60 + parseInt(quietTime.end.split(':')[1]);

            if (currentTime >= quietStart && currentTime < quietEnd) {
                setIsQuietTime(true);
                setResetWarningSound(false); // Do not reset sound during ongoing quiet time
            } else {
                setIsQuietTime(false);
                setResetWarningSound(true); // Reset sound order when quiet time ends
            }
        };

        const interval = setInterval(checkQuietTime, 1000); // Check every second for more precision
        return () => clearInterval(interval);
    }, [quietTime]);

    const handleManualQuietTime = () => {
        if (manualQuietTime) {
            // Quiet time ends, reset the warning sounds
            setResetWarningSound(true);
        } else {
            // Quiet time starts, reset the warning sounds
            setResetWarningSound(false);
        }
        setManualQuietTime((prevState) => !prevState);
    };

    return (
        <div>
            <h1>Office Quiet Time</h1>
            <TrafficLight 
                isQuietTime={isQuietTime || manualQuietTime} 
                toggleQuietTime={handleManualQuietTime} // Pass the handler to toggle quiet time
            />
            <QuietTimeScheduler setQuietTime={setQuietTime} />

            {/* Wrap button in a container for styling */}
            <div className="button-container-center">
                <button onClick={handleManualQuietTime}>
                    {manualQuietTime ? 'End Quiet Time' : 'Start Quiet Time'}
                </button>
            </div>

            <NoiseDetector isQuietTime={isQuietTime || manualQuietTime} resetWarningSound={resetWarningSound} />
        </div>
    );
};

export default App;
