import React, { useState } from 'react';

const QuietTimeScheduler = ({ setQuietTime }) => {
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setQuietTime({ start: startTime, end: endTime });
    };

    const handleCancel = () => {
        setStartTime('');  // Reset start time
        setEndTime('');    // Reset end time
        setQuietTime({ start: '', end: '' });  // Clear the scheduled quiet time
    };

    return (
        <div className="form-container">
            <form className="time-inputs" onSubmit={handleSubmit}>
                <div className="time-input">
                    <label htmlFor="startTime">Start Time</label>
                    <input
                        type="time"
                        id="startTime"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                    />
                </div>
                <div className="time-input">
                    <label htmlFor="endTime">End Time</label>
                    <input
                        type="time"
                        id="endTime"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                    />
                </div>
                <div className="button-container">
                    <button type="submit">Schedule Quiet Time</button>
                    <button type="button" onClick={handleCancel}>Cancel</button>
                </div>
            </form>
        </div>
    );
};

export default QuietTimeScheduler;
